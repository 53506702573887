import { Text, useToast } from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';
import {
  ShareResults,
  useShareAssignmentResultsMutation,
} from '@lon/shared/requests';
import { types } from './duck';
import { Modal } from './components';

const ShareAssignmentResultsModal: React.FC<
  types.ShareAssignmentResultsModalProps
> = ({ onClose, isOpen, isMultiple = false, studentsData, onCompleted }) => {
  const { t } = useTranslation();

  const [fetch, { loading }] = useShareAssignmentResultsMutation();
  const toast = useToast();

  const handleSubmit = (onCloseModal: () => void) => {
    return async (values: {
      notes: string;
      includeCorrectAnswers: boolean;
    }) => {
      const payloads = studentsData.reduce<
        {
          assignmentId: string;
          studentIds: string[];
          notes: string;
          shareResults: ShareResults;
        }[]
      >((acc, val) => {
        const existingPayload = acc.find(
          (item) => item.assignmentId === val.assignmentId
        );

        if (existingPayload) {
          existingPayload.studentIds.push(val.studentId as string);
        } else {
          acc.push({
            assignmentId: val.assignmentId,
            studentIds: [val.studentId as string],
            notes: values.notes,
            shareResults: values.includeCorrectAnswers
              ? ShareResults.WithCorrectAnswers
              : ShareResults.WithoutCorrectAnswers,
          });
        }

        return acc;
      }, []);

      try {
        const result = await Promise.all(
          payloads.map((payload) => {
            return fetch({
              variables: { params: payload },
            });
          })
        );
        const areAllSuccessful = result.every(
          (res) => res?.data?.putActivitiesShareResults?.success
        );
        const areSomeSuccessful = result.some(
          (res) => res?.data?.putActivitiesShareResults?.success
        );

        const successToast = (eligibleStudentsAmount: number) => {
          toast({
            title: t('assignments.shareSuccessToastTitle'),
            description: (
              <Text whiteSpace="pre-wrap">
                {isMultiple
                  ? t('assignments.shareSuccessToastDescriptionMultiple', {
                      amount: eligibleStudentsAmount,
                    })
                  : sanitize(
                      t('assignments.shareSuccessToastDescription', {
                        name: `${studentsData[0]?.firstName} ${studentsData[0]?.lastName}`,
                        interpolation: {
                          escapeValue: false,
                        },
                      })
                    )}
              </Text>
            ),
            variant: 'success-light',
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
        };

        if (areAllSuccessful) {
          const eligibleStudentsAmount = payloads.reduce((acc, val) => {
            return acc + (val?.studentIds?.length || 0);
          }, 0);
          successToast(eligibleStudentsAmount);
          onCompleted?.();
          onCloseModal();
          return;
        } else if (areSomeSuccessful) {
          result.forEach((item, index) => {
            if (item.data?.putActivitiesShareResults?.success) {
              successToast(payloads[index]?.studentIds?.length);
            }
          });
          onCompleted?.();
          onCloseModal();
          throw new Error();
        } else {
          throw new Error();
        }
      } catch (e) {
        toast({
          title: t('systemMessages.038'),
          status: 'error',
          variant: 'error-light',
          isClosable: true,
          duration: null,
        });
      }
    };
  };

  return (
    <Modal
      isMultiple={isMultiple}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loading}
      isOpen={isOpen}
      studentName={`${studentsData[0]?.firstName} ${studentsData[0]?.lastName}`}
    />
  );
};

export default ShareAssignmentResultsModal;
