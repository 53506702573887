import chmln from '@chamaeleonidae/chmln';
import { isEmpty } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { useAuth } from '@lon/shared/hooks';
import { UserTypeEnum } from '@lon/shared/requests';
import { chamToken } from './constants';

export const useChameleon = () => {
  const chameleonRef = useRef(false);
  const [{ user }] = useAuth();

  useEffect(() => {
    if (isEmpty(user)) {
      return;
    }

    let role = '';

    if (
      user?.type === UserTypeEnum.Parent ||
      user?.type === UserTypeEnum.Student
    ) {
      const type = user.type;
      role = type.charAt(0).toUpperCase() + type.slice(1);
    } else {
      user?.userRoles?.forEach((r) => {
        role = role += r.code;
      });
    }

    if (!chameleonRef.current && !user.impersonated) {
      chameleonRef.current = true;

      chmln.init(chamToken, { fastUrl: 'https://fast.chameleon.io/' });

      chmln.identify(user?.userId || '', {
        email: user?.email || '',
        name: `${user?.lastName}, ${user?.firstName}` || '',
        role: role,
        product: 'TempSubscriptionInfo',
        district: user?.districtId || '',
        state: user?.state || '',
      });
    }
  }, []);
};
