import {
  Box,
  Button,
  Icon as ChakraIcon,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { sanitize } from 'dompurify';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { closeOutlined } from '@lon/shared/assets';
import { FormTextarea, Icon, ReactDatePicker } from '@lon/shared/components';
import { dateFormats, sxLightScrollBar } from '@lon/shared/constants';
import { useReopenAssignmentMutation } from '@lon/shared/requests';
import { types, utils } from './duck';
import {
  ErrorMessage,
  InfoMessage,
  IntroMessage,
  WarningMessage,
} from './components';
import styles from './ReopenAssignmentModal.module.css';

const ReopenAssignmentModal: React.FC<types.ReopenAssignmentModalProps> = ({
  isOpen,
  onClose,
  isGroupActions = false,
  endDate,
  studentAssignments,
  onCompleted,
}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const studentName = `${studentAssignments?.canBeReopened[0]?.firstName} ${studentAssignments?.canBeReopened[0]?.lastName}`;
  const { validationSchema, defaultValues } = utils.getValidation();
  const [reopenAssignment, { loading }] = useReopenAssignmentMutation();
  const formProviderProps = useForm<types.Form>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: 'onSubmit',
  });

  const allStudentsAreIneligible =
    studentAssignments?.canNotBeReopened.length &&
    !studentAssignments.canBeReopened.length;
  const someStudentsAreIneligible =
    studentAssignments?.canNotBeReopened.length &&
    studentAssignments.canBeReopened.length;

  const onCloseModal = () => {
    formProviderProps.reset();
    onClose();
  };

  const handleSubmit = async (values: types.Form) => {
    const payloads =
      studentAssignments?.canBeReopened.reduce<
        {
          assignmentId: string;
          studentIds: string[];
          notes: string;
          endDate: string | null;
        }[]
      >((acc, val) => {
        if (!val.assignmentId) {
          return acc;
        }

        const existingPayload = acc.find(
          (item) => item.assignmentId === val.assignmentId
        );

        if (existingPayload) {
          existingPayload.studentIds.push(val.studentId as string);
        } else {
          acc.push({
            assignmentId: val.assignmentId || '',
            studentIds: [val.studentId as string],
            notes: values.notes,
            endDate: values.endDate ? utils.formatDate(values.endDate) : null,
          });
        }

        return acc;
      }, []) || [];

    try {
      const result = await Promise.all(
        payloads.map((payload) => {
          return reopenAssignment({
            variables: { sessionsParams: payload },
          });
        })
      );
      const areAllSuccessful = result.every(
        (res) => res?.data?.reopenAssignment?.success
      );
      const areSomeSuccessful = result.some(
        (res) => res?.data?.reopenAssignment?.success
      );

      const successToast = (eligibleStudentsAmount: number) => {
        toast({
          title: t('reopenAssignmentModal.reopenSuccessToastTitle'),
          description: (
            <Text whiteSpace="pre-wrap">
              {isGroupActions
                ? t(
                    'reopenAssignmentModal.reopenSuccessToastDescriptionMultiple',
                    {
                      amount: eligibleStudentsAmount,
                    }
                  )
                : sanitize(
                    t('reopenAssignmentModal.reopenSuccessToastDescription', {
                      name: studentName,
                      interpolation: {
                        escapeValue: false,
                      },
                    })
                  )}
            </Text>
          ),
          variant: 'success-light',
          status: 'success',
          isClosable: true,
          duration: 3000,
        });
      };

      if (areAllSuccessful) {
        const eligibleStudentsAmount = payloads.reduce((acc, val) => {
          return acc + (val?.studentIds?.length || 0);
        }, 0);
        successToast(eligibleStudentsAmount);
        onCompleted?.();
        onCloseModal();
        return;
      } else if (areSomeSuccessful) {
        result.forEach((item, index) => {
          if (item.data?.reopenAssignment?.success) {
            successToast(payloads[index]?.studentIds?.length);
          }
        });
        onCompleted?.();
        onCloseModal();
        throw new Error();
      } else {
        throw new Error();
      }
    } catch (e) {
      toast({
        title: t('systemMessages.038'),
        status: 'error',
        variant: 'error-light',
        isClosable: true,
        duration: null,
      });
    }
  };

  return (
    <>
      <Modal
        variant="rounded"
        isCentered
        isOpen={isOpen}
        onClose={onCloseModal}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent data-focus-visible-disabled>
          <FormProvider {...formProviderProps}>
            <Flex
              as="form"
              noValidate
              direction="column"
              overflow="hidden"
              id="datepicker-portal"
              onSubmit={formProviderProps.handleSubmit(handleSubmit)}
            >
              <ModalHeader flexDirection="column" p="0" borderBottom="none">
                <Flex
                  w="full"
                  align="center"
                  justify="space-between"
                  gap="6"
                  py="4"
                  px="8"
                  borderBottom="1px solid #e1e7f0"
                >
                  {t('reopenAssignmentModal.header')}
                  <IconButton
                    aria-label={t('reopenAssignmentModal.closeModal')}
                    size="md"
                    variant="inverted"
                    onClick={onCloseModal}
                    icon={<ChakraIcon as={closeOutlined} w="24px" h="24px" />}
                  />
                </Flex>
                <Box
                  w="full"
                  py="2"
                  px="8"
                  boxShadow="0px 2px 7px 0px rgba(43, 54, 70, 0.10)"
                >
                  <Text variant="n1">
                    {sanitize(
                      t('reopenAssignmentModal.subHeader', {
                        target: isGroupActions
                          ? t('reopenAssignmentModal.multipleStudents')
                          : studentName,
                        interpolation: {
                          escapeValue: false,
                        },
                      })
                    )}
                  </Text>
                </Box>
              </ModalHeader>
              <ModalBody css={sxLightScrollBar}>
                <Flex
                  direction="column"
                  gap="4"
                  sx={{
                    label: {
                      mb: 2,
                    },
                  }}
                >
                  {allStudentsAreIneligible ? (
                    <>
                      <ErrorMessage />
                      <InfoMessage />
                    </>
                  ) : (
                    <>
                      <IntroMessage endDate={endDate} />
                      {someStudentsAreIneligible ? (
                        <WarningMessage
                          students={studentAssignments?.canNotBeReopened}
                        />
                      ) : null}
                      <Box position="relative">
                        <ReactDatePicker
                          isRequired
                          showCurrentDatePicker
                          name="endDate"
                          placeholder={t('reopenAssignmentModal.dateFormat')}
                          calendarClassName={styles['react-datepicker']}
                          popperPlacement="bottom-end"
                          dateFormat={dateFormats.monthDayYear}
                          minDate={new Date()}
                          portalId="datepicker-portal"
                          label={
                            <Text
                              as="span"
                              title={t('reopenAssignmentModal.endDateLabel')}
                              isTruncated
                            >
                              {t('reopenAssignmentModal.endDateLabel')}
                            </Text>
                          }
                          inputLayoutProps={{
                            formLabelProps: {
                              display: 'flex',
                              overflow: 'hidden',
                            },
                          }}
                        />
                      </Box>
                      <FormTextarea
                        name="notes"
                        height="148px"
                        placeholder={t(
                          `assignments.${
                            isGroupActions
                              ? 'studentNoteGroupPlaceholder'
                              : 'studentNotePlaceholder'
                          }`
                        )}
                        data-user-input="true"
                        label={
                          <>
                            <Text
                              variant="n1"
                              textAlign="left"
                              color="primary.800"
                              {...(isGroupActions && { mb: '2' })}
                            >
                              {t('reopenAssignmentModal.studentNoteLabel')}
                            </Text>
                            {isGroupActions && (
                              <Text variant="s3" color="primary.300">
                                {studentAssignments?.canNotBeReopened.length
                                  ? t(
                                      'reopenAssignmentModal.studentNoteRuleExplanationAllowed'
                                    )
                                  : t(
                                      'reopenAssignmentModal.studentNoteRuleExplanationAll'
                                    )}
                              </Text>
                            )}
                          </>
                        }
                      />
                    </>
                  )}
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button
                  isDisabled={loading}
                  colorScheme="blue"
                  mr={3}
                  onClick={onCloseModal}
                >
                  <Text as="span">
                    {t('reopenAssignmentModal.cancelButton')}
                  </Text>
                </Button>
                <Button
                  isLoading={loading}
                  isDisabled={
                    !studentAssignments?.canBeReopened.length ||
                    !formProviderProps.formState.isDirty
                  }
                  variant="solid"
                  type="submit"
                  leftIcon={<Icon name="check-outlined" size="md" />}
                >
                  <Text as="span">
                    {t('reopenAssignmentModal.reopenButton')}
                  </Text>
                </Button>
              </ModalFooter>
            </Flex>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReopenAssignmentModal;
