import { Icon } from '../icon';
import {
  Box,
  Button,
  Link as ChakraLink,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  SITE_FOOTER_HEIGHT,
  SITE_FOOTER_Z_INDEX_BASE,
  SITE_FOOTER_Z_INDEX_MD,
} from '@lon/shared/constants';
import {
  isProdCluster,
  prodStemDomain,
  stemDomain,
} from '@lon/shared/constants';
import {
  FullScreenContext,
  WorkingLocation,
  useFullScreen,
} from '@lon/shared/contexts';
import {
  ApplicationEnum,
  DistrictPermission,
  UserPurpose,
} from '@lon/shared/enums';
import {
  useAuth,
  useMediaQuery,
  usePermission,
  useThemeStyleValue,
} from '@lon/shared/hooks';
import { UserPurposeEnum, UserTypeEnum } from '@lon/shared/requests';
import { ChatPopup, Feedback } from './components';

export const Footer: React.FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  const { t } = useTranslation();
  const [isDesktop] = useMediaQuery('(min-width: 1280px)');
  const [isTablet] = useMediaQuery('(min-width: 768px)');
  const [isBigMobile] = useMediaQuery('(min-width: 430px)');
  const [{ user }] = useAuth();
  const { application } = useContext(WorkingLocation);
  const { setIsFullScreen } = useFullScreen();
  const { setIsFullScreen: setTeacherIsFullScreen } =
    useContext(FullScreenContext);

  const [canAccessPerryBot] = usePermission([
    {
      permission:
        application === ApplicationEnum.STUDENT_SUIT
          ? DistrictPermission.STUDENT_SUIT
          : DistrictPermission.TEACHER_SUIT,
      module: 'learningBot',
      operation: 'access',
    },
  ]);

  const isStudent =
    user?.type?.toLocaleLowerCase() ===
    UserTypeEnum.Student.toLocaleLowerCase();
  const helpButtonVariant = useThemeStyleValue('ghost', 'outline');

  const isParentSuite = user?.type === UserTypeEnum.Parent;

  // todo: add logic to enable/disable chat based on Platform Admin rules
  const perryBotEnabled = true;

  React.useEffect(() => {
    (window as any).ReadSpeaker?.init();
  }, []);

  React.useEffect(() => {
    if (perryBotEnabled) {
      const perryBotEnabledEvent = new CustomEvent('PERRY_BOT_ENABLED', {
        detail: { enabled: true },
      });
      dispatchEvent(perryBotEnabledEvent);
    }
  }, []);

  const closeFullScreen = () => {
    setTeacherIsFullScreen && setTeacherIsFullScreen(false);
    setIsFullScreen(false);
  };

  const renderFooterStub = () =>
    !isBigMobile && perryBotEnabled ? null : (
      <Flex justify="center" align="center" m="0 auto" py="15px"></Flex>
    );

  return (
    <Box
      as="footer"
      backgroundColor={useThemeStyleValue('white', 'secondary.1000')}
      borderTop={useThemeStyleValue('none', '1px solid #fff')}
      boxShadow="2xl"
      zIndex={{
        base: SITE_FOOTER_Z_INDEX_BASE,
        md: SITE_FOOTER_Z_INDEX_MD,
      }}
      width="100%"
      maxW="var(--page-width-max)"
      margin="0 auto"
      {...(perryBotEnabled ? { pb: { base: '3.5rem', b430: 0 } } : {})}
      {...(!isTablet && perryBotEnabled && { position: 'relative' })}
    >
      <Flex
        px={isAdmin ? 4 : { base: 4, b768: 8 }}
        h={isAdmin ? '60px' : { base: '60px', b768: `${SITE_FOOTER_HEIGHT}px` }}
        justify="center"
        align="center"
        position="relative"
      >
        {!isAdmin && !isParentSuite && !isStudent && renderFooterStub()}
        {(isAdmin || isParentSuite || isStudent) && (
          <Flex
            gap={10}
            display={{
              base: 'none',
              lg: 'flex',
            }}
          >
            <Text
              as="span"
              display="flex"
              gap={1.5}
              flexDirection="row"
              color="primary.800"
              aria-hidden="true"
              fontSize={{ base: '14px', md: 'sm' }}
              lineHeight={{ md: 'short' }}
              fontWeight={500}
            >
              &copy; 2024{' '}
              <ChakraLink
                href={
                  isProdCluster() ? prodStemDomain : `${stemDomain}/home.php`
                }
                isExternal
                color="primary.800"
                aria-label={t('footer.accelerateLearning')}
                textDecoration="underline"
                fontSize={{ base: '14px', md: 'sm' }}
                lineHeight={{ md: 'short' }}
                fontWeight={500}
                onClick={closeFullScreen}
              >
                {t('footer.accelerateLearning')}
              </ChakraLink>
            </Text>
            <ChakraLink
              href={
                isProdCluster()
                  ? `${prodStemDomain}/terms`
                  : `${stemDomain}/terms`
              }
              isExternal
              color="primary.800"
              textDecoration="underline"
              fontSize={{ base: '14px', md: 'sm' }}
              lineHeight={{ md: 'short' }}
              fontWeight={500}
              aria-label={t('footer.termsAndCondition')}
              onClick={closeFullScreen}
            >
              {t('footer.termsAndCondition')}
            </ChakraLink>
          </Flex>
        )}
        {isAdmin &&
          !user?.impersonated &&
          user?.purpose !== UserPurposeEnum.PreviewAccount && (
            <Box position="absolute" right={6} top={!isTablet ? 2 : '14px'}>
              <Feedback isAdmin={isAdmin} closeFullScreen={closeFullScreen} />
            </Box>
          )}
        {!isAdmin && (
          <Flex
            position={{
              base: 'static',
              b430: 'absolute',
            }}
            right={{
              b430: 6,
            }}
            justify={
              perryBotEnabled
                ? { base: 'center', b430: 'flex-end' }
                : 'flex-end'
            }
            align="center"
            gap="6"
          >
            {!isStudent &&
              !isParentSuite &&
              !user?.impersonated &&
              user?.purpose !== UserPurposeEnum.PreviewAccount && (
                <Feedback isAdmin={isAdmin} closeFullScreen={closeFullScreen} />
              )}
            {!isParentSuite && (
              <Tooltip
                variant="dark"
                label={t('footer.helpCenterTooltip')}
                aria-hidden={true}
              >
                <Button
                  aria-label={t('footer.helpCenterTooltip')}
                  variant={helpButtonVariant}
                  boxShadow="0px 5px 20px -2px #2B364612"
                  size={!isTablet ? 'md' : 'sm'}
                  px={!isTablet ? 1 : 2}
                  as={Link}
                  to={isStudent ? '/help/home' : '/more/help/home'}
                  onClick={closeFullScreen}
                >
                  <Icon
                    name="bulbOutlined"
                    {...(!isTablet && { width: '20px', height: '20px' })}
                  />
                  {isTablet && (
                    <Text as="span" isTruncated ml="1.5">
                      {isDesktop
                        ? t('footer.helpCenter')
                        : isTablet
                        ? t('footer.helpCenterShort')
                        : ''}
                    </Text>
                  )}
                </Button>
              </Tooltip>
            )}
            {canAccessPerryBot && !isParentSuite && <ChatPopup />}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default Footer;
