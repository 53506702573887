import { Icon } from '../../../../../../../../../icon';
import { navigationTypes } from '../../duck';
import {
  Box,
  Button,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import clsx from 'clsx';
import { uniqueId } from 'lodash-es';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '@lon/shared/configs';
import {
  CONTENT_ACTIONS_TOOLBAR_HEIGHT,
  sxLightScrollBar,
} from '@lon/shared/constants';
import {
  DESKTOP_CONTENT_PADDING,
  PAGE_HEADER_HEIGHT,
  SITE_FOOTER_HEIGHT,
  SITE_HEADER_HEIGHT,
  TOOLBAR_HEIGHT,
  TOOLBAR_MENU_CONTENT_PADDING,
} from '@lon/shared/constants';
import { useOutsideClick } from '@lon/shared/hooks';
import { HideElement } from '@lon/shared/types';
import styles from './SectionItem.module.css';

const SectionItem: React.FC<navigationTypes.SectionItemProps> = ({
  id,
  name,
  index,
  elements,
  scopesListSearch,
  ...rest
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { scopeId, sectionId, projectId, elementId } = useParams<{
    scopeId: string;
    sectionId: string;
    projectId: string;
    elementId: string;
  }>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuButtonRef = useRef<HTMLElement>(null);

  const visibleElements = elements.filter(
    (element) => !(element?.metadata?.hide === HideElement.Yes)
  );

  const handleLinkClick = (pathname: string) => {
    const content = document.querySelector('.page-content');
    content?.scrollTo(0, 0);
    navigate({
      pathname,
      search: scopesListSearch,
    });
  };

  const buttonTextId = useMemo(() => uniqueId('submenu_button_'), []);

  const menuRef = useOutsideClick<HTMLUListElement>((event) => {
    if (
      isOpen &&
      (event.target === window ||
        !menuButtonRef.current?.contains(event.target as Node))
    ) {
      onClose();
    }
  });

  return (
    <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose} strategy="fixed">
      <MenuButton
        {...rest}
        as={Button}
        variant="solid"
        ref={menuButtonRef}
        size="sm"
        className={clsx(styles.sectionMenuButton, {
          [styles.activeSection]: sectionId === id,
        })}
        background="primary.900"
        maxWidth={350}
        title={name}
        rightIcon={
          <Icon
            name={isOpen ? 'chevronUp' : 'chevronDown'}
            width="20px"
            height="20px"
          />
        }
      >
        <Text isTruncated id={buttonTextId}>
          {name}
        </Text>
      </MenuButton>
      <MenuList
        onKeyDown={(event) => {
          if (
            ['Escape', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.code)
          ) {
            onClose();
          }
        }}
        as="ul"
        aria-labelledby={buttonTextId}
        wordBreak="break-word"
        data-focus-visible-disabled
        w={{ base: '100vw', sm: '368px' }}
        backgroundColor="secondary.50"
        py={0}
        borderBottomRadius="md"
        ref={menuRef}
      >
        <Box
          px="4"
          pb="4"
          borderRadius="md"
          sx={{
            '.chakra-menu__group__title': {
              color: 'primary.500',
              textTransform: 'uppercase',
              fontSize: '0.75rem',
              fontWeight: '400',
              m: '6',
            },
          }}
        >
          <MenuGroup title={t('scope.activities')} textTransform="capitalize">
            <Box
              maxH={`calc(100vh - (${
                SITE_HEADER_HEIGHT +
                PAGE_HEADER_HEIGHT +
                TOOLBAR_HEIGHT +
                CONTENT_ACTIONS_TOOLBAR_HEIGHT +
                SITE_FOOTER_HEIGHT +
                DESKTOP_CONTENT_PADDING
              }px + ${TOOLBAR_MENU_CONTENT_PADDING}px))`}
              overflowY="auto"
              css={sxLightScrollBar}
            >
              {visibleElements.map((element) => {
                const pathname = generatePath(routes.scopes.element, {
                  scopeId,
                  sectionId: id,
                  elementId: element.identifier,
                  projectId,
                });

                return (
                  <MenuItem
                    key={element.identifier}
                    as="li"
                    onClick={() => handleLinkClick(pathname)}
                    id={element?.identifier}
                    aria-controls="scope-element"
                    aria-selected={
                      `/suite${pathname}` === window.location.pathname
                    }
                    p={0}
                    minH={10}
                    position="relative"
                    {...(index !== visibleElements.length - 1 && {
                      borderBottomWidth: '1px',
                      borderBottomStyle: 'solid',
                      borderBottomColor: 'secondary.100',
                    })}
                    {...(index === 0 && {
                      borderTopRadius: 'md',
                    })}
                    {...(index === visibleElements.length - 1 && {
                      borderBottomRadius: 'md',
                    })}
                  >
                    {elementId === element.identifier && (
                      <Box
                        position="absolute"
                        top="6.5px"
                        left={0}
                        width="4px"
                        height="calc(100% - 1rem)"
                        bg="primary.400"
                        zIndex={10}
                        borderTopRightRadius="6px"
                        borderBottomRightRadius="6px"
                      />
                    )}
                    <Link
                      title={t('scope.openScopeElement', {
                        name: element?.metadata?.elementName,
                      })}
                      href={pathname}
                      onClick={(e) => e.preventDefault()}
                    >
                      <Flex flex="1" px="6" py="2.5">
                        <Text variant="s2" wordBreak="break-word" maxW="290px">
                          {`${element?.metadata?.elementName || ''}${
                            element?.teacherView?.metadata?.activityTitle
                              ? `: ${element?.teacherView?.metadata?.activityTitle}`
                              : ''
                          }`}
                        </Text>
                      </Flex>
                    </Link>
                  </MenuItem>
                );
              })}
            </Box>
          </MenuGroup>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default React.memo(SectionItem);
