import md5 from 'md5';
import React, { useContext } from 'react';
import { WorkingLocation } from '@lon/shared/contexts';
import {
  ApplicationEnum,
  DistrictPermission,
  ProfileTypeEnum,
} from '@lon/shared/enums';
import { useAuth, usePermission } from '@lon/shared/hooks';

const Coding: React.FC = () => {
  const [auth] = useAuth();
  const { application } = useContext(WorkingLocation);
  const [hasCoding] = usePermission([
    {
      permission: [
        ApplicationEnum.TEACHER_SUIT,
        ApplicationEnum.CAMPUS_LEADER_SUIT,
        ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT,
        ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT,
      ].includes(application as ApplicationEnum)
        ? DistrictPermission.TEACHER_SUIT
        : DistrictPermission.STUDENT_SUIT,
      module: 'coding',
      operation: 'view',
    },
  ]);
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);

  React.useEffect(() => {
    const listener = (e: MessageEvent): void => {
      if (
        e.data.state === 'ready' &&
        iframeRef.current &&
        iframeRef.current?.contentWindow
      ) {
        iframeRef.current!.contentWindow.postMessage(
          {
            globalId: auth.user.userId,
            md5GlobalId: md5(`${auth.user.userId}`),
            profileType:
              auth.profileType === ProfileTypeEnum.STUDENT
                ? 'student'
                : 'teacher',
            bitsboxAccess: true,
          },
          '*'
        );
      }
    };

    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  }, [auth.user]);

  React.useEffect(() => {
    if (hasCoding) {
      const waitForElement = (callback: () => void) => {
        const intervalId = setInterval(() => {
          if (iframeRef?.current?.contentWindow?.document) {
            clearInterval(intervalId);
            callback();
          }
        }, 500);

        return intervalId;
      };

      const interval = waitForElement(() => {
        const iframe = iframeRef?.current?.contentWindow?.document;

        if (iframe) {
          iframe.addEventListener(
            'click',
            (e: any) => {
              if (
                e?.target?.className === 'snap-close-button' ||
                e?.target?.id === 'bitsbox-run-tablet-iframe-close-button'
              ) {
                e.stopImmediatePropagation();
                e.preventDefault();
                if (
                  (iframeRef?.current?.contentWindow as any)?.snapEdit?.cancel
                ) {
                  (
                    iframeRef?.current?.contentWindow as any
                  )?.snapEdit?.cancel();
                }
              }
              if (
                [
                  'orderby-difficulty',
                  'orderby-name',
                  'orderby-projectType',
                  'orderby-lines',
                ].includes(e?.target?.id)
              ) {
                e.stopImmediatePropagation();
                e.preventDefault();
                if ((iframeRef?.current?.contentWindow as any)?.bitsbox?.sort) {
                  (iframeRef?.current?.contentWindow as any).bitsbox.sort(
                    e.target
                  );
                }
              } else if (
                e?.target?.id === 'toggle-search-text' ||
                e?.target?.parentElement?.id === 'toggle-search-text'
              ) {
                e.stopImmediatePropagation();
                e.preventDefault();

                if (
                  (iframeRef?.current?.contentWindow as any)?.bitsbox
                    ?.toggleAdvancedSearch
                ) {
                  (
                    iframeRef?.current?.contentWindow as any
                  ).bitsbox.toggleAdvancedSearch();
                }
              } else if (
                e?.target?.parentElement?.className === 'search-block__see-all'
              ) {
                e.stopImmediatePropagation();
                e.preventDefault();

                if (
                  (iframeRef?.current?.contentWindow as any)?.bitsbox
                    ?.filterAllButton
                ) {
                  (
                    iframeRef?.current?.contentWindow as any
                  ).bitsbox.filterAllButton(e.target.parentElement);
                }
              } else if (e?.target?.className === 'search-block__submit') {
                e.stopImmediatePropagation();
                e.preventDefault();

                const form = e?.target?.closest('form');

                if (form) {
                  (iframeRef?.current?.contentWindow as any)?.bitsbox?.search(
                    form
                  );
                }
              } else if (
                e?.target?.className === 'flat-button__text' ||
                e?.target?.className === 'search-clear-button'
              ) {
                e.stopImmediatePropagation();
                e.preventDefault();

                const form = e?.target?.closest('form');
                const clearButton = e?.target?.closest('.search-block__reset');

                if (
                  clearButton &&
                  (iframeRef?.current?.contentWindow as any)?.bitsbox
                    ?.clearSearchForm
                ) {
                  (
                    iframeRef?.current?.contentWindow as any
                  )?.bitsbox?.clearSearchForm();
                }
                if (
                  form &&
                  (iframeRef?.current?.contentWindow as any)?.bitsbox?.search
                ) {
                  (iframeRef?.current?.contentWindow as any)?.bitsbox?.search(
                    form
                  );
                }
              } else if (e?.target?.className?.includes('toggle-panel-head')) {
                e.stopImmediatePropagation();
                e.preventDefault();
                e?.target?.parentElement.classList.toggle('collapsed');
                e?.target?.parentElement.classList.toggle('visible');
              }
            },
            true
          );
        }
      });

      return () => clearInterval(interval);
    }
    return () => {};
  }, [hasCoding]);

  return (
    <iframe
      width="100%"
      title={`bitsbox-coding`}
      ref={iframeRef}
      src="coding-tool/index.html"
    />
  );
};

export default Coding;
