import { AssignmentFormContext } from '../../../../duck/context';
import {
  Box,
  Checkbox,
  Flex,
  Radio,
  Stack,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import { isAfter } from 'date-fns';
import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormCheckbox,
  FormRadioGroup,
  Icon,
  Message,
  ReactDatePicker,
} from '@lon/shared/components';
import { dateFormats } from '@lon/shared/constants';
import { useMediaQuery } from '@lon/shared/hooks';
import { constants } from './duck';

const Assignment: React.FC = () => {
  const { t } = useTranslation();
  const startDate = useWatch({ name: 'startDate' });
  const endDate = useWatch({ name: 'endDate' });
  const {
    immediatelyShareResults,
    setImmediatelyShareResults,
    isTeacherAssessment,
    isDistrictAssessment,
    isReadOnlyMode,
    isEditAssignmentMode,
    hasManuallyGradableQuestions,
    isLeaderSuit,
  } = useContext(AssignmentFormContext);
  const [isTablet] = useMediaQuery('(max-width: 1024px)');
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const isAssessment = isTeacherAssessment || isDistrictAssessment;

  const showLeaderWarning =
    isLeaderSuit && isAssessment && hasManuallyGradableQuestions;
  return (
    <TabPanel {...(isAssessment && { as: Box, padding: 0 })}>
      <Flex flexDir="column" alignItems="center">
        <Box w="full" {...(!isTablet && { maxW: '700px' })}>
          <Flex
            display="flex"
            w="full"
            gap={isMobile ? '4' : '6'}
            my={isMobile || isAssessment ? '4' : '6'}
            flexDir={isMobile ? 'column' : 'row'}
          >
            <Box
              position="relative"
              flex="1"
              maxWidth={isMobile ? '100%' : '50%'}
            >
              <ReactDatePicker
                isDisabledStable={isReadOnlyMode}
                isRequired
                selectsStart
                showCurrentDatePicker
                name="startDate"
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                maxDate={endDate}
                label={
                  <Text
                    as="span"
                    isTruncated
                    title={t('createAssignmentDrawer.startDate')}
                  >
                    {t('createAssignmentDrawer.startDate')}
                  </Text>
                }
                defaultValue={new Date()}
                placeholder={t('createAssignmentDrawer.dateFormat')}
                dateFormat={dateFormats.monthDayYear}
                isCurrentPickerDisabled={isAfter(
                  new Date().setHours(0, 0, 0, 0),
                  endDate
                )}
                inputLayoutProps={{
                  formLabelProps: {
                    display: 'flex',
                    overflow: 'hidden',
                  },
                }}
              />
            </Box>
            <Box
              position="relative"
              flex="1"
              maxWidth={isMobile ? '100%' : '50%'}
            >
              <ReactDatePicker
                isDisabledStable={isReadOnlyMode}
                isRequired
                name="endDate"
                selectsEnd
                showCurrentDatePicker
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                label={
                  <Text
                    as="span"
                    title={t('createAssignmentDrawer.endDate')}
                    isTruncated
                  >
                    {t('createAssignmentDrawer.endDate')}
                  </Text>
                }
                defaultValue={null}
                placeholder={t('createAssignmentDrawer.dateFormat')}
                dateFormat={dateFormats.monthDayYear}
                isCurrentPickerDisabled={isAfter(startDate, new Date())}
                inputLayoutProps={{
                  formLabelProps: {
                    display: 'flex',
                    overflow: 'hidden',
                  },
                }}
              />
            </Box>
          </Flex>
          <Stack spacing="2">
            {!isDistrictAssessment && (
              <>
                <FormCheckbox
                  isDisabledStable={isReadOnlyMode || isEditAssignmentMode}
                  label={
                    <Text variant="n3">
                      {t('createAssignmentDrawer.allowLateSubmission')}
                    </Text>
                  }
                  name="lateTurnIn"
                  color="blue.800"
                  size="md"
                  checkboxProps={{ spacing: 3, gap: 0 }}
                />
                <Checkbox
                  {...((isReadOnlyMode || isEditAssignmentMode) && {
                    'data-disabled-stable': true,
                  })}
                  isDisabled={isReadOnlyMode || isEditAssignmentMode}
                  spacing={3}
                  color="blue.800"
                  w="fit-content"
                  isChecked={immediatelyShareResults}
                  onChange={(e) => setImmediatelyShareResults(e.target.checked)}
                >
                  <Text variant="n3">
                    {t('createAssignmentDrawer.immediatelyShareResults')}
                  </Text>
                </Checkbox>
                <FormRadioGroup
                  name="immediatelyShareResults"
                  isDisabled={
                    !immediatelyShareResults ||
                    isReadOnlyMode ||
                    isEditAssignmentMode
                  }
                >
                  <Stack spacing={2} pl="72px">
                    {constants.immediatelyShareResultsOptions.map(
                      ({ value, label }) => (
                        <Radio
                          {...((isReadOnlyMode || isEditAssignmentMode) && {
                            'data-disabled-stable': true,
                          })}
                          key={value}
                          value={value}
                          w="fit-content"
                        >
                          <Text variant="n3">{label}</Text>
                        </Radio>
                      )
                    )}
                  </Stack>
                </FormRadioGroup>
              </>
            )}
            {isAssessment && (
              <FormCheckbox
                label={
                  <Text variant="n3">
                    {t('createAssignmentDrawer.randomizeQuestionOrder')}
                  </Text>
                }
                name="randomizeQuestionsOrder"
                color="blue.800"
                size="md"
                checkboxProps={{ spacing: 3, gap: 0 }}
              />
            )}
            {showLeaderWarning && (
              <Message
                mt={2}
                css={{
                  '.chakra-alert__desc': { display: 'block', padding: 0 },
                }}
                message={
                  <Text variant="s3" whiteSpace="pre-line">
                    {t('createAssignmentDrawer.manualGradableWarning')}
                  </Text>
                }
                variant="formWarning"
                icon={<Icon name="lamp" />}
              />
            )}
          </Stack>
        </Box>
      </Flex>

      {/* TODO: need to enable when teacher access functionality fully implemented */}
      {/* {isDistrictAssessment && (
        <>
          <Divider
            orientation="horizontal"
            {...(isTablet && {
              w: `calc(100% + 2 * ${TABLET_TAB_PANEL_PADDING})`,
              ml: `-${TABLET_TAB_PANEL_PADDING}`,
            })}
          />
          <Flex flexDir="column" alignItems="center">
            <Box w="full" {...(!isTablet && { maxW: '700px' })}>
              <FormCheckboxGroup
                name="teacherAccess"
                formLabelProps={{
                  mb: 0,
                }}
                label={
                  <Text
                    variant="n1"
                    textAlign="left"
                    color="primary.800"
                    mb="4"
                  >
                    {t('createAssignmentDrawer.teacherAccess')}
                  </Text>
                }
              >
                <Flex
                  flexWrap="wrap"
                  gap={isTablet ? '4' : '6'}
                  flexDir={isMobile ? 'column' : 'row'}
                >
                  {constants.teacherActionsOptions.map(
                    ({ value, label, description }) => (
                      <Checkbox
                        key={value}
                        id={value}
                        value={value}
                        spacing="4"
                        color="blue.800"
                        display="flex"
                        alignItems="flex-start"
                        sx={{
                          '.chakra-checkbox__control': {
                            mt: '1',
                          },
                        }}
                      >
                        <Text variant="n3">{label}</Text>
                        <Text
                          whiteSpace="pre-wrap"
                          variant="s3"
                          color="primary.300"
                          mt="2"
                          {...(!isMobile && { minW: '300px' })}
                        >
                          {description}
                        </Text>
                      </Checkbox>
                    )
                  )}
                </Flex>
              </FormCheckboxGroup>
            </Box>
          </Flex>
        </>
      )} */}
    </TabPanel>
  );
};

export default Assignment;
