import { Answers } from '../components';
import { Flex, Text } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { t } from 'i18next';
import {
  StudentAssignmentGrade,
  StudentAssignmentStatus,
} from '@lon/shared/components';
import { STATUS_SEQUENCE } from '@lon/shared/constants';
import { ThemeEnum } from '@lon/shared/enums';
import { StudentAssignmentStatus as StudentAssignmentStatusEnum } from '@lon/shared/requests';
import {
  getGradesFromAnswers,
  getStudentAssignmentStatus,
  getThemeStyleValue,
  getTimeInterval,
} from '@lon/shared/utils';
import { PreparedDistrictAssignmentData } from '@lon/suit/types';
import { sortLastFirstnameAZ } from '@lon/suit/utils';
import { Classes, StudentAssignment, Students } from './types';

const columnHelper = createColumnHelper<PreparedDistrictAssignmentData>();

export const nameColumn = (currentTheme: ThemeEnum) =>
  columnHelper.accessor('lastName', {
    cell: (info) => {
      return (
        <Text
          as="span"
          className="notranslate"
          variant="s2"
          color={getThemeStyleValue('primary.800', 'white')(currentTheme)}
          textDecoration="none"
          _hover={{ textDecoration: 'none' }}
        >
          {`${info.row.original.lastName}, ${info.row.original.firstName}`}
        </Text>
      );
    },
    header: t('assessmentDetails.columnStudent') as string,
    sortingFn: sortLastFirstnameAZ,
  });

export const statusColumn = columnHelper.accessor('transformedStatus', {
  cell: (info) => {
    return <StudentAssignmentStatus status={info.getValue()} />;
  },
  sortingFn: (a, b) => {
    if (a.original.transformedStatus && b.original.transformedStatus) {
      return (
        STATUS_SEQUENCE[a.original.transformedStatus] -
        STATUS_SEQUENCE[b.original.transformedStatus]
      );
    }
    return 0;
  },
  header: t('assessmentDetails.columnStatus') as string,
  meta: {
    headerAlign: 'center',
    cellAlign: 'center',
  },
});

export const gradeColumn = columnHelper.accessor('grade', {
  cell: (info) => {
    const answers = info.row.original.answers;
    const { grade, isGraded } = getGradesFromAnswers(answers);

    return (
      <Flex justify="center" align="center" h="full">
        <StudentAssignmentGrade
          grade={typeof grade === 'number' ? Math.round(grade) : grade}
          completedAt={info.row.original.completedAt}
          submittedAt={info.row.original.submittedAt}
          isGraded={isGraded}
        />
      </Flex>
    );
  },
  sortingFn: (a, b) => {
    const firstAnswers = a.original.answers;
    const { grade: firstGradeFromAnswers } = getGradesFromAnswers(firstAnswers);
    const secondAnswers = b.original.answers;
    const { grade: secondGradeFromAnswers } =
      getGradesFromAnswers(secondAnswers);
    const firstCompleted = !!a.original.submittedAt && !!a.original.completedAt;
    const secondCompleted =
      !!b.original.submittedAt && !!b.original.completedAt;
    const firstGradeIndex =
      firstCompleted && typeof firstGradeFromAnswers === 'number'
        ? firstGradeFromAnswers
        : firstCompleted && typeof firstGradeFromAnswers !== 'number'
        ? -1
        : -2;
    const secondGradeIndex =
      secondCompleted && typeof secondGradeFromAnswers === 'number'
        ? secondGradeFromAnswers
        : secondCompleted && typeof secondGradeFromAnswers !== 'number'
        ? -1
        : -2;

    return firstGradeIndex - secondGradeIndex;
  },
  header: t('assessmentDetails.columnGrade') as string,
  meta: {
    headerAlign: 'center',
    cellAlign: 'center',
  },
});

export const getColumns = (
  currentTheme: ThemeEnum,
  showAnswersColumn?: boolean
): ColumnDef<PreparedDistrictAssignmentData, any>[] => {
  const columns = [
    nameColumn(currentTheme),
    columnHelper.accessor('class', {
      cell: (info) => {
        return <Text variant="s2"> {info.getValue()}</Text>;
      },
      header: t('assessmentDetails.columnClass') as string,
    }),
    statusColumn,
    columnHelper.accessor('time', {
      cell: (info) => {
        const period = info.getValue();

        return (
          <Text variant="s2" minW="70px" pr={1} whiteSpace="nowrap">
            {getTimeInterval(period?.startedAt, period?.completedAt)}
          </Text>
        );
      },
      sortingFn: (a, b) => {
        const aPeriod =
          a.original.time?.startedAt && a.original.time?.completedAt
            ? new Date(a.original.time?.completedAt).getTime() -
              new Date(a.original.time?.startedAt).getTime()
            : 0;
        const bPeriod =
          b.original.time?.startedAt && b.original.time?.completedAt
            ? new Date(b.original.time?.completedAt).getTime() -
              new Date(b.original.time?.startedAt).getTime()
            : 0;

        return aPeriod - bPeriod;
      },
      header: t('assessmentDetails.columnTime') as string,
      meta: {
        headerAlign: 'right',
        cellAlign: 'right',
      },
    }),
    gradeColumn,
  ].filter((item) => item !== null);

  if (showAnswersColumn) {
    columns.push(
      columnHelper.accessor('answers', {
        cell: (info) => {
          return <Answers answers={info.getValue()} />;
        },
        header: t('assessmentDetails.columnAnswers') as string,
        enableSorting: false,
      }) as any
    );
  }

  return columns as ColumnDef<PreparedDistrictAssignmentData, any>[];
};

export const getAssignmentData = ({
  classes,
  studentAssignments,
  students,
}: {
  classes?: Classes;
  studentAssignments?: StudentAssignment[];
  students?: Students;
}) =>
  studentAssignments
    ?.filter((studentAssignment) => {
      const currentStudent = students?.find((student) => {
        return student?._id === studentAssignment?.studentId;
      });

      return !!currentStudent;
    })
    ?.map((studentAssignment) => {
      const currentClass = classes?.find((classItem) => {
        return classItem?._id === studentAssignment?.classId;
      });

      const currentStudent = students?.find((student) => {
        return student?._id === studentAssignment?.studentId;
      });

      return {
        transformedStatus: getStudentAssignmentStatus(
          (studentAssignment?.status || '') as StudentAssignmentStatusEnum
        ),
        firstName: currentStudent?.firstName,
        lastName: currentStudent?.lastName,
        time: {
          startedAt: studentAssignment?.startedAt,
          completedAt: studentAssignment?.completedAt,
          endDate: studentAssignment?.endDate,
        },
        answers:
          typeof studentAssignment?.answers === 'string'
            ? JSON.parse(studentAssignment?.answers || '[]')
            : studentAssignment?.answers,
        class: currentClass?.name,
        studentId: studentAssignment?.studentId,
        id: studentAssignment?.id,
        grade: studentAssignment?.grade,
        referenceLearnosityId: studentAssignment?.learnosityReferenceId,
        completedAt: studentAssignment?.completedAt,
        submittedAt: studentAssignment?.submittedAt,
        shareResults: studentAssignment?.shareResults,
        assignmentId: studentAssignment?.assignmentId,
        status: studentAssignment?.status,
        schoolId: studentAssignment?.schoolId,
      };
    });
