// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeEnum } from '@lon/shared/enums';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getThemeStyleValue } from '@lon/shared/utils';

export const getInput = (theme: ThemeEnum) => {
  return {
    defaultProps: {
      size: 'md',
    },
    baseStyle: {
      field: {
        borderRadius: '4px',
        color: getThemeStyleValue('primary.800', 'secondary.1000')(theme),

        '&::placeholder': {
          color: getThemeStyleValue('primary.400', 'secondary.1000')(theme),
        },

        '::-webkit-search-cancel-button': {
          WebkitAppearance: 'none',
        },

        _focus: {
          borderColor: 'blue.100',
          outline: 'none',
          boxShadow: 'none',
          background: 'blue.50',
        },

        _focusVisible: {
          borderColor: 'blue.100',
          outline: 'none',
          boxShadow: 'none',
          background: 'blue.50',
        },

        _disabled: {
          opacity: 1,
          background: 'secondary.100',
          borderColor: 'primary.50',
          color: 'blue.400',

          _hover: {
            opacity: 1,
            background: 'secondary.100',
            borderColor: 'primary.50',
            color: 'primary.300',
          },
        },
      },
    },
    variants: {
      outline: {
        field: {
          bg: 'white',
          border: '1px',
          borderColor: 'primary.200',
          borderRadius: '4px',

          '&::placeholder': {
            fontStyle: 'italic',
          },

          _hover: {
            borderColor: 'primary.200',
          },

          _focusVisible: {
            borderColor: 'blue.100',
            outline: 'none',
            boxShadow: 'focus.blue',
            background: 'white',
          },

          _disabled: {
            opacity: 1,

            _hover: {
              opacity: 1,
            },
          },
        },
      },
      dark: {
        field: {
          bg: 'white',
          // border: '1px',
          // borderColor: 'primary.200',
          borderRadius: '4px',

          '&::placeholder': {
            fontStyle: 'italic',
          },

          _hover: {
            borderColor: 'blue.100',
          },

          _focusVisible: {
            boxShadow: 'focus.blue',
            outline: 'none',
            background: 'white',
          },

          _focus: {
            boxShadow: 'focus.blue',
            outline: 'none',
            background: 'white',
          },

          _disabled: {
            opacity: 1,

            _hover: {
              opacity: 1,
            },
          },
        },
      },
      searchField: {
        field: {
          borderWidth: '3px',
          borderColor: 'blue.400',
          borderRadius: '3px',
          fontSize: 'sm',
          color: 'blue.400',
          paddingRight: 9,
          paddingLeft: 1.5,
        },
        element: {
          h: 'calc(100% - 6px)',
          w: '30px',
          mt: '3px',
          mr: '3px',
          bg: 'blue.400',
          borderWidth: '3px',
          borderColor: 'blue.400',
        },
      },
      'rl-input': {
        field: {
          bg: 'white',
          border: '1px',
          borderColor: '#D6D6D6',
          borderRadius: 0,

          _hover: {
            borderColor: 'blue.100',
          },

          _focusVisible: {
            borderColor: 'blue.100',
            outline: 'none',
            boxShadow: 'focus',
            background: 'white',
          },

          _disabled: {
            opacity: 1,

            _hover: {
              opacity: 1,
            },
          },
        },
      },
      sharp: {
        field: {
          bg: 'white',
          border: '1px',
          borderColor: 'primary.200',
          borderRadius: '0',

          '&::placeholder': {
            fontStyle: 'italic',
          },

          _invalid: {
            borderColor: '#E53E3E',
            boxShadow: '0 0 0 1px #e53e3e',
          },

          _hover: {
            borderColor: 'blue.100',
          },

          _focusVisible: {
            borderColor: 'blue.100',
            outline: 'none',
            boxShadow: 'none',
            background: 'white',
          },

          _disabled: {
            opacity: 1,

            _hover: {
              opacity: 1,
            },
          },
        },
      },
      disabledStable: {
        field: {
          border: 'none',
          padding: '0',
          _disabled: {
            cursor: 'default',
            background: 'transparent',
            color: 'primary.800',

            _hover: {
              background: 'transparent',
              color: 'primary.800',
            },
          },
        },
      },
      readonly: {
        field: {
          border: 'none',
          padding: '0',
          cursor: 'default',
          _focusVisible: {
            borderColor: 'none',
            outline: 'none',
            boxShadow: 'none',
            background: 'transparent',
          },
        },
      },
    },
    sizes: {
      xxs: {
        field: {
          height: '32px',
          minHeight: '32px',
          paddingLeft: 2,
          fontSize: '0.875rem',
        },
      },
      xs: {
        field: {
          minHeight: '36px',
        },
        addon: {
          minHeight: '36px',
        },
      },
      sm: {
        field: {
          minHeight: '40px',
        },
      },
      md: {
        field: {
          minHeight: '44px',
        },
      },
    },
  };
};
