import {
  Box,
  Button,
  DrawerFooter,
  Flex,
  HStack,
  TabPanel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { isEmpty } from 'lodash-es';
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ConfirmModal,
  FormSelect,
  Icon,
  IconButton,
  RequiredNotification,
  TextInput,
  useToast,
} from '@lon/shared/components';
import { sxLightScrollBar } from '@lon/shared/constants';
import { GradeLevelEnum } from '@lon/shared/requests';
import {
  CurriculumAreaEnum,
  useUpdateUserMutation,
} from '@lon/shared/requests';
import { Auth } from '@lon/shared/types';
import {
  clearQueryCache,
  enumToOptions,
  handleError,
  parseJSON,
} from '@lon/shared/utils';
import { Course, FormData, Props, getInitialData, getValidation } from './duck';
import { StatusLabel } from './components';

const Courses = React.forwardRef<
  {
    closeSettings: () => void;
    changeTab: (index: number) => void;
  },
  Props
>(
  (
    {
      setIsPreferencesOpen,
      setIsDirtyForm,
      isFormDirty,
      setCurrentTab,
      initialTabIndex,
    },
    externalRef
  ) => {
    const { user } = useSelector((state: { auth: Auth }) => state.auth);
    const { t } = useTranslation();
    const toast = useToast();
    const [changeTab, setChangeTab] = useState<number | null>(null);
    const {
      isOpen: isConfirmOpen,
      onOpen: onConfirmOpen,
      onClose: onConfirmClose,
    } = useDisclosure();
    const applicationSettings = useSelector(
      (state: { applicationSettings: any }) => state.applicationSettings
    );
    const [updateUser, { loading }] = useUpdateUserMutation();
    const curriculumAreas = useMemo(
      () => enumToOptions(CurriculumAreaEnum),
      []
    );
    const isFormDirtyRef = React.useRef(false);

    const preferences = applicationSettings?.preferences;
    const savedCourses = (parseJSON(preferences) as any)?.courses;
    const gradesOptions = React.useMemo(
      () =>
        enumToOptions(GradeLevelEnum).map((option) => ({
          ...option,
          label: t(`gradeLevels.${option.value}`),
        })),
      [t]
    );

    const { validationSchema, defaultValues } = getValidation(t, preferences);

    const formProviderProps = useForm<FormData>({
      resolver: yupResolver(validationSchema),
      defaultValues,
      mode: 'onChange',
    });

    const { fields, append } = useFieldArray({
      control: formProviderProps.control,
      name: 'courses',
    });

    useEffect(() => {
      if (preferences) {
        resetToDefault();
      }
    }, [preferences]);

    useEffect(() => {
      if (savedCourses && savedCourses?.length !== fields?.length) {
        isFormDirtyRef.current = true;
        setIsDirtyForm(true);
      } else {
        isFormDirtyRef.current = false;
        setIsDirtyForm(!isEmpty(formProviderProps.formState.dirtyFields));
      }
    }, [fields, formProviderProps.getValues()?.courses?.length]);

    const resetToDefault = () => {
      formProviderProps.reset(getInitialData(parseJSON(preferences)));
    };

    const addCourse = () => {
      append({
        name: '',
        gradeLevel: '',
        curriculumArea: '',
        saved: false,
      });
    };

    const removeCourse = (index: number) => {
      const values = formProviderProps.getValues().courses;
      const removedTeacher =
        values.filter((_: any, idx: number) => idx !== index) || [];
      formProviderProps.reset({
        courses: removedTeacher,
      });
    };

    const handleCloseSettings = () => {
      formProviderProps.trigger();
      setChangeTab(null);
      if (
        !isEmpty(formProviderProps.formState.dirtyFields) ||
        isFormDirty ||
        isFormDirtyRef.current
      ) {
        onConfirmOpen();
      } else {
        setIsPreferencesOpen(false);
      }
    };

    const handleChangeTab = (index: number) => {
      formProviderProps.trigger();
      setChangeTab(index);
      if (
        !isEmpty(formProviderProps.formState.dirtyFields) ||
        isFormDirty ||
        isFormDirtyRef.current
      ) {
        onConfirmOpen();
      } else {
        setCurrentTab(index);
      }
    };

    useImperativeHandle(
      externalRef,
      () => ({
        closeSettings: handleCloseSettings,
        changeTab: handleChangeTab,
      }),
      []
    );

    const findDuplicatedIndexes = (courses: Course[]) =>
      courses.reduce<number[]>((acc, course, index) => {
        if (
          courses.findIndex((c) => c.name === course.name) !== index &&
          !acc.includes(index)
        ) {
          acc.push(index);
        }
        return acc;
      }, []);

    const onSubmit = formProviderProps.handleSubmit(async (values) => {
      const duplicatedIndexes = findDuplicatedIndexes(values?.courses);
      if (duplicatedIndexes?.length) {
        duplicatedIndexes?.forEach((v) => {
          formProviderProps.setError(`courses.${v}.name`, {
            type: 'validation',
            message: t('userSettings.courses.errorMessageNotUnique', {
              entity: v + 1,
            }),
          });
        });
        return;
      }

      updateUser({
        variables: {
          input: {
            id: `/api/user-management/api/users/${user.userId}`,
            preferences: JSON.stringify({
              ...parseJSON(applicationSettings?.preferences),
              courses: values?.courses?.map((v) => ({
                ...v,
                saved: true,
              })),
            }),
          },
        },
      })
        .then(() => {
          toast({
            title: t('userSettings.courses.updatedSuccessfullyTitle'),
            variant: 'success-light',
            isClosable: true,
          });

          clearQueryCache('applicationSettings');
        })
        .catch((error: any) => {
          handleError({ error, toast });
        });
    });

    const onClose = (shouldCloseForm?: boolean) => {
      if (shouldCloseForm) {
        setIsPreferencesOpen(false);
      } else if (typeof changeTab === 'number') {
        setCurrentTab(changeTab);
      } else {
        setIsPreferencesOpen(false);
      }
      setCurrentTab(initialTabIndex || 0);
      formProviderProps.reset(getInitialData(parseJSON(preferences)));
      onConfirmClose();
    };

    const closeForm = () => {
      if (
        formProviderProps.formState.isDirty ||
        !isEmpty(formProviderProps.formState.dirtyFields) ||
        isFormDirty ||
        isFormDirtyRef.current
      ) {
        onConfirmOpen();
      } else {
        onClose(true);
      }
    };

    return (
      <TabPanel as="form" p={0} noValidate onSubmit={onSubmit} w="full">
        <FormProvider {...formProviderProps}>
          <Flex
            display="flex"
            direction="column"
            color="primary.800"
            h="calc(100vh - 9.25rem)"
          >
            <Box
              overflow="auto"
              h="calc(100vh - 9.25rem)"
              sx={sxLightScrollBar}
            >
              <Box
                py={6}
                pl={{ base: 5, md: 7, lg: '150px' }}
                pr={{ base: 5, md: 7, lg: '125px' }}
                borderBottom="1px solid"
                borderColor="secondary.200"
              >
                <RequiredNotification />
              </Box>
              <Flex justify="space-between">
                <Box pt={4} w="full">
                  <Text
                    variant="s3"
                    color="primary.400"
                    pl={{ base: 5, md: 7, lg: '150px' }}
                    pr={{ base: 5, md: 7, lg: '125px' }}
                  >
                    <Trans i18nKey={'userSettings.courses.description'} />
                  </Text>
                  {fields?.map((item: any, index: number) => {
                    return (
                      <Box
                        pb={6}
                        pl={{ base: 5, md: 7, lg: '150px' }}
                        pr={{ base: 5, md: 7, lg: '125px' }}
                        borderBottom="1px solid"
                        borderColor="secondary.200"
                      >
                        <Flex
                          py={4}
                          w="100%"
                          position="relative"
                          alignItems="center"
                          justify="space-between"
                        >
                          <Box w="100%" position="relative">
                            <Flex position="absolute" right={0} top={0}>
                              <StatusLabel
                                index={index}
                                data={savedCourses || []}
                              />
                              <Box display={{ base: 'block', lg: 'none' }}>
                                <Button
                                  ml={2}
                                  zIndex={1}
                                  onClick={() => removeCourse(index)}
                                  rightIcon={
                                    <Icon name="delete-outlined" size="lg" />
                                  }
                                  size="md"
                                  variant="link"
                                  textDecoration="underline"
                                >
                                  {t('userSettings.courses.delete')}
                                </Button>
                              </Box>
                            </Flex>
                            <TextInput
                              label={t('userSettings.courses.courseName', {
                                entity: `${index + 1}`,
                              })}
                              maxLength={30}
                              name={`courses.${index}.name`}
                              isRequired
                              placeholder={t(
                                'userSettings.courses.coursePlaceholder',
                                {
                                  entity: `${index + 1}`,
                                }
                              )}
                            />
                          </Box>
                          <IconButton
                            aria-label={t('userSettings.courses.delete')}
                            variant="ghost"
                            position="absolute"
                            right={'-4rem'}
                            top="50px"
                            display={{ base: 'none', lg: 'block' }}
                            icon={<Icon name="delete-outlined" size="lg" />}
                            onClick={() => removeCourse(index)}
                          />
                        </Flex>
                        <Flex
                          w="100%"
                          flexDir={{ base: 'column', sm: 'row' }}
                          justify="space-between"
                          align="flex-start"
                        >
                          <Box w={{ base: '100%', sm: '48%' }}>
                            <FormSelect
                              isRequired
                              label={t(
                                'userSettings.courses.curriculumAreaLabel'
                              )}
                              placeholder={t(
                                'userSettings.courses.curriculumAreaPlaceholder'
                              )}
                              returnObject={false}
                              name={`courses.${index}.curriculumArea`}
                              options={curriculumAreas}
                            />
                          </Box>
                          <Box w={{ base: '100%', sm: '48%' }}>
                            <FormSelect
                              isRequired
                              name={`courses.${index}.gradeLevel`}
                              label={t('userSettings.courses.gradeLevelLabel')}
                              placeholder={t(
                                'userSettings.courses.gradeLevelPlaceholder'
                              )}
                              options={gradesOptions}
                              returnObject={false}
                            />
                          </Box>
                        </Flex>
                      </Box>
                    );
                  })}
                </Box>
              </Flex>
              <Flex
                mb={4}
                w="full"
                pl={{ base: 5, md: 7, lg: '150px' }}
                pr={{ base: 5, md: 7, lg: '125px' }}
                mt="1.375rem"
              >
                <Flex w="100%" justifyContent="flex-end">
                  <Button
                    onClick={addCourse}
                    variant="outline"
                    size="sm"
                    leftIcon={<AiOutlinePlus />}
                  >
                    {t('userSettings.courses.addNewCourse')}
                  </Button>
                </Flex>
              </Flex>
            </Box>
            <DrawerFooter
              h="5.75rem"
              borderTopWidth="1px"
              justifyContent="flex-start"
              py={0}
              flex="none"
            >
              <HStack justify="space-between" width="100%">
                <Button
                  leftIcon={<Icon name="arr-left-outlined" />}
                  onClick={closeForm}
                >
                  {t('userSettings.teams.cancel')}
                </Button>
                <Button
                  leftIcon={<Icon name="check-outlined" />}
                  variant="solid"
                  type="submit"
                  isDisabled={
                    loading ||
                    !(
                      formProviderProps.formState.isDirty ||
                      isFormDirty ||
                      isFormDirtyRef.current
                    )
                  }
                >
                  {t('userSettings.teams.save')}
                </Button>
              </HStack>
            </DrawerFooter>
          </Flex>
        </FormProvider>
        <ConfirmModal
          primaryHeading={t('userSettings.modal.title')}
          subHeading={t('userSettings.modal.description', {
            entity: 'Courses',
          })}
          dontSaveBtnText={t('userSettings.modal.cancel')}
          submitButtonText={t('userSettings.modal.save')}
          hasDontSaveBtn
          hasSaveBtn={formProviderProps.formState.isValid}
          isModalOpen={isConfirmOpen}
          handleSave={() => {
            onSubmit();
            onConfirmClose();
            onClose();
          }}
          handleDontSave={onClose}
          handleCancel={onConfirmClose}
          isLoading={loading}
          dontSaveBtnProps={{
            variant: 'solidDark',
            leftIcon: <Icon name="delete-outlined" size="lg" />,
            px: 4,
          }}
          submitBtnProps={{
            leftIcon: <Icon name="check-outlined" size="md" />,
            px: 4,
          }}
          modalSize={{ base: 'full', sm: '500px' }}
        />
      </TabPanel>
    );
  }
);

export default Courses;
