import { Asterisk } from '../../asterisk';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { get } from 'lodash-es';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { types } from './duck';

const FormTextarea: React.FC<types.TextareaProps> = ({
  name,
  showError = true,
  label,
  isRequired,
  isDisabledStable,
  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { onBlur, onChange, name: fieldName, value, ref },
    formState: { errors },
  } = useController({ name, control });
  const errorMessage = get(errors, `${fieldName}.message`, '') as string;

  return (
    <FormControl
      isDisabled={rest?.isDisabled}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
    >
      {label && <FormLabel requiredIndicator={<Asterisk />}>{label}</FormLabel>}
      {isDisabledStable ? (
        <Text variant="n4" color="primary.400" pt="2">
          {value ? value : 'none'}
        </Text>
      ) : (
        <Textarea
          name={fieldName}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          ref={ref}
          borderColor="primary.200"
          {...rest}
        />
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export default FormTextarea;
