import { Icon } from '../../icon';
import { MenuItem } from '../../menu';
import actionsStyles from '../ToolbarActions.module.css';
import {
  Button,
  IconButton,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getQuery } from '@lon/api';
import { hideTTS, showTTS } from '@lon/shared/constants';
import { useQueryParams } from '@lon/shared/hooks';
import { SpeechProps } from './duck';

const Speech: React.FC<SpeechProps> = ({ isResponsive, ...rest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isTablet] = useMediaQuery('(min-width: 768px)');
  const [queryParams] = useQueryParams();
  const isTTSVisible = queryParams.tts === showTTS;
  const title = isTTSVisible
    ? t('toolbar.disableSpeech')
    : t('toolbar.enableSpeech');

  const setShowTTS = (tts = 'ON', replace = true) =>
    navigate('?' + getQuery({ ...queryParams, tts }), { replace });

  return (
    <MenuItem {...rest}>
      <>
        <Tooltip variant="dark" label={title} aria-hidden={true}>
          {isTablet && isResponsive ? (
            <Button
              size="md"
              variant="ghost"
              className={actionsStyles.action}
              aria-label={title}
              /* eslint-disable-next-line jsx-a11y/aria-props */
              aria-description={t('toolbar.ariaSpeechDescription')}
              leftIcon={
                <Icon name={isTTSVisible ? 'no-sound' : 'sound'} size="lg" />
              }
              onClick={() => {
                setShowTTS(isTTSVisible ? hideTTS : showTTS, false);
              }}
            >
              <Text as="span" isTruncated variant="bs">
                {t('toolbar.speech')}
              </Text>
            </Button>
          ) : (
            <IconButton
              size="md"
              variant="solidDark"
              className={actionsStyles.action}
              aria-label={title}
              /* eslint-disable-next-line jsx-a11y/aria-props */
              aria-description={t('toolbar.ariaSpeechDescription')}
              onClick={() => {
                setShowTTS(isTTSVisible ? hideTTS : showTTS, false);
              }}
            >
              <Icon
                name={isTTSVisible ? 'no-sound' : 'sound'}
                size="lg"
              />
            </IconButton>
          )}
        </Tooltip>
      </>
    </MenuItem>
  );
};

export default Speech;
